.App {
    text-align: center;
}


/* Input Adornment */
.MuiInputAdornment-positionEnd {
    margin-right: 20px !important;
}

.MuiInputAdornment-positionStart {
    margin-left: 20px !important;
}


/* Horizontal MENU */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;   /* IE and Edge */
    scrollbar-width: none;      /* Firefox */
}

.react-horizontal-scrolling-menu--scroll-container {
    flex-basis: 100%;
    flex-shrink: 0;
}
